import React from "react";
import {Link} from "gatsby";
import { Helmet } from "react-helmet";
import PageWrapper from "../../../components/PageWrapper";
import Hero from "../../../sections/how-to/Hero";
import Main from "../../../sections/how-to/Main";
import Features from "../../../sections/common/Features";
import VideoDemo from "../../../sections/common/VideoDemo";
import Wave from "../../../sections/common/Wave";
import CTA from "../../../sections/CTA/CTA";
import SEO from '../../../components/SEO';
import metaImg from '../../../assets/image/metaimg/howto.jpg';

const PricingPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Make 'How to' videos with simple user guided overlays"
          description="Guide you users with step by step instructions using our timely content overlays"
          image={metaImg}
        />    
        <Helmet>
        <link
          href={'https://cdn.digma.io/dist/digma.min.1.2.0.css?v=instructional'}
          rel="stylesheet"
          />
          <script async src={'https://cdn.digma.io/dist/digma.min.1.2.0.js?v=instructional'}></script>
        </Helmet>                  
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries">Industries</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/instructional">Instructional</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li> 
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/instructional/how-to">How To</Link>
              </li>                            
              </ol>
           </nav>
        </div>           
        <Hero />
        <Wave pos="top" color="#0c426d" />
        <VideoDemo className="bg-digma-dark" name="Ikea Assembly Video" projectId="d7830347-9b30-40b5-a303-7551676a55f5" />        
        <Wave pos="bottom" color="#F7F9FC" background="#0c426d" />         <Main className="grey" />
        <Features />
        <CTA className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default PricingPage;
